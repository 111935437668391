<template>
  <v-card>
    <v-card-title>
      <h3>Content Version History</h3>
      <v-spacer />
      <v-text-field
        v-model="keywords"
        append-icon="mdi-magnify"
        label="Search Versions..."
        clearable
        single-line
        hide-details
      />
    </v-card-title>
    <v-card-text>
      <version-grid
        :keywords.sync="keywords"
        :reload.sync="reloadGrid"
        :client_id.sync="this.$store.getters.userForPortalAccess.client.id"
        @open-version-dialog="openVersionDialog"
      />
    </v-card-text>
    <v-dialog
      v-model="versionDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <version-dialog
        :pool.sync="currentPool"
        @close-version-dialog="closeVersionDialog()"
        @version-updated="reloadTheGrid"
      />
    </v-dialog>
  </v-card>
</template>
<script>
import VersionGrid from "@/views/user-dashboard/versions/VersionGrid";
import VersionDialog from "@/views/user-dashboard/versions/VersionDialog";
import { mapGetters } from "vuex";
export default {
  name: "home",
  components: {
    "version-grid": VersionGrid,
    "version-dialog": VersionDialog
  },
  data: () => ({
    keywords: "",
    reloadGrid: 0,
    versionDialog: false,
    currentPool: {}
  }),
  computed: {
    ...mapGetters({
      authUser: "authUser"
    })
  },
  methods: {
    openVersionDialog(data) {
      this.currentPool = data;
      this.versionDialog = true;
    },
    closeVersionDialog: function() {
      this.versionDialog = false;
    },
    reloadTheGrid: function() {
      this.reloadGrid++;
    }
  }
};
</script>
