<template>
  <v-card>
    <v-toolbar dark color="primary">
      <v-btn icon dark @click="$emit('close-version-dialog')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>{{ pool.name }} Versions</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn dark text @click="$emit('close-version-dialog')">Close</v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-card-text>
      <v-list two-line>
        <v-list-item v-for="version in pool.versions" :key="version.id">
          <v-list-item-action>
            <div class="text-center">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    class="mx-2"
                    fab
                    small
                    :loading="downloadLoader"
                    :disabled="downloadLoader"
                    @click="downloadVersion(version.id)"
                  >
                    <v-icon>mdi-cloud-download</v-icon>
                  </v-btn>
                </template>
                <span>Download</span>
              </v-tooltip>
            </div>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              <v-icon fab left>
                {{ typeIcon(version.build_type) }}
              </v-icon>
              {{ version.version }}</v-list-item-title
            >
            <v-list-item-subtitle>
              Uploaded:
              {{ formatDate(version.created_at) }}</v-list-item-subtitle
            >
            <span v-if="version.notes !== ''">
              Notes:<br />
              {{ version.notes }}</span
            >
            <span v-if="version.is_current === 'y'"
              >Current {{ version.build_type }} Version</span
            >
            <span v-if="version.is_testing === 'y'"
              >Testing {{ version.build_type }}Version</span
            ><br />
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
//import axios from "axios";
export default {
  name: "VersionDialog",
  props: {
    pool: {
      type: Object,
      default: () => ({
        name: "",
        id: "",
        versions: []
      })
    }
  },
  data: () => ({
    downloadLoader: false
  }),
  methods: {
    typeIcon: function(build_type) {
      if (build_type === "web") {
        return "mdi-earth";
      } else {
        return "mdi-file-pdf-box";
      }
    },
    async downloadVersion(id) {
      window.open(
        process.env.VUE_APP_API_ENDPOINT + "contentdownload/" + id,
        "_blank"
      );
      // this can be refactored to use axios and get the token from the getInstance...but...this is okay too...
      /*
      this.downloadLoader = true;
      await axios
        .get(process.env.VUE_APP_API_ENDPOINT + "/contentdownload/" + id, {
          responseType: "blob"
        })
        .then(response => {
          const blob = new Blob([response.data], { type: "application/zip" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = "archive.zip";
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(console.error);
      this.downloadLoader = false;

         */
    },
    formatDate: function(date) {
      return new Date(date).toLocaleDateString("en-us", {
        hour: "numeric",
        minute: "numeric",
        year: "numeric",
        month: "long",
        day: "numeric",
        hour12: true
      });
    }
  }
};
</script>

<style scoped></style>
